import {
    handleQueryResolve
} from '../utils'

// PatientID, GenericName = '', Strength = '', extendedWhere = 'rx.RxDate > DATEADD(month, -6, GetDate())'
export default function (GroupID) {
  // FIXME - need to set this up to work with the query for the blister pack
  if (GroupID) {
    const attrs = [{
      param: 'GroupID',
      type: 'sql.Int',
      value: GroupID, // to support like
    }]
    const where = ['GroupId = @GroupID']

    return this.query(`
        SELECT "DBA"."GroupPatient"."PatientId" as PatientID, 
        "DBA"."GroupPatient"."GroupId",
        "DBA"."Patient"."LastName",
        "DBA"."Patient"."FirstName",
        "DBA"."Patient"."DateOfBirth" as Birthday
        FROM "DBA"."GroupPatient" JOIN "DBA"."Patient" ON "DBA"."GroupPatient"."PatientId" = "DBA"."Patient"."Id"
        WHERE ${where}
        `, attrs).then(handleQueryResolve)
  }
}
